import AccountDTO, { GrantedLocationDTO } from "models/account/account.dto";
import AccountResponse, {
  GrantedLocationResponse,
} from "models/account/account.response";

function responseToDTO(response: AccountResponse): AccountDTO {
  return {
    id: response.id,
    login: response.login,
    firstName: response.first_name,
    lastName: response.last_name,
    email: response.email,
    imageUrl: response.image_url,
    activated: response.activated,
    langKey: response.lang_key,
    createdBy: response.created_by,
    createdAt: response.created_at,
    updatedBy: response.updated_by,
    updatedAt: response.updated_at,
    authorities: response.authorities,
    gender: response.gender,
    birthday: response.birthday,
    address1: response.address1,
    country: response.country,
    city: response.city,
    district: response.district,
    phone: response.phone,
    positionId: response.position_id,
    positionTitle: response.position_title,
    departmentId: response.department_id,
    departmentName: response.department_name,
    grantedLocation: mapGrantedLocationResponseToModel(
      response.granted_location
    ),
    displayName: response.display_name,
  };
}

function mapGrantedLocationResponseToModel(
  response: GrantedLocationResponse
): GrantedLocationDTO {
  return {
    locationSelection: response.location_selection,
    locationIds: response.location_ids,
  };
}

const accountLoginMap = {
  responseToDTO,
};

export default accountLoginMap;
