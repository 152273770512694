function OrderMenuIcon(props: React.ComponentPropsWithoutRef<"svg">) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    >
      <rect x="3" y="3.47046" width="18" height="19" rx="3" fill="#FAB005" />
      <path
        d="M14 3.47046C14 2.36589 13.1046 1.47046 12 1.47046C10.8954 1.47046 10 2.36589 10 3.47046H8V5.47046C8 6.02274 8.44772 6.47046 9 6.47046H15C15.5523 6.47046 16 6.02274 16 5.47046V3.47046H14Z"
        fill="#FFEECD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 11.4705C7 10.9182 7.44772 10.4705 8 10.4705L16 10.4705C16.5523 10.4705 17 10.9182 17 11.4705C17 12.0227 16.5523 12.4705 16 12.4705L8 12.4705C7.44772 12.4705 7 12.0227 7 11.4705Z"
        fill="#FFEECD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 15.4705C7 14.9182 7.44772 14.4705 8 14.4705L12 14.4705C12.5523 14.4705 13 14.9182 13 15.4705C13 16.0227 12.5523 16.4705 12 16.4705H8C7.44772 16.4705 7 16.0227 7 15.4705Z"
        fill="#FFEECD"
      />
    </svg>
  );
}

export default OrderMenuIcon;
