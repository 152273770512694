import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@mantine/notifications/styles.css";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "mantine-react-table/styles.css"; //make sure MRT styles were imported in your app root (once)
import { store } from "store";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18n, { initPromise } from "./i18n";
import "dayjs/locale/vi"; // Import locale
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
// Chờ đợi i18n khởi tạo hoàn tất trước khi render ứng dụng
initPromise.then(() => {
  root.render(
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </Provider>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
