import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import adminService from "service/admin/admin.service";
import accountLoginMap from "shared/maps/account-login";
import { SSO_POPUP_BROADCAST_CHANNEL_KEY } from "shared/utils/auth";
import { RootState } from "store";
import { setAccountLoginAction, setLogin } from "store/slices/user.slice";

const useAccountLogin = () => {
  const dispatch = useDispatch();
  const isLogin = useSelector((state: RootState) => state.user?.isLogin);

  useEffect(() => {
    const channel = new BroadcastChannel(SSO_POPUP_BROADCAST_CHANNEL_KEY);
    if (isLogin) {
      channel.postMessage("Đăng nhập thành công");
      channel.close();
    }
    return () => {
      channel.close();
    };
  }, [isLogin]);

  useEffect(() => {
    (async () => {
      try {
        const response = await adminService.getAccountByLogin();
        const accountModel = accountLoginMap.responseToDTO(response.data);
        dispatch(setAccountLoginAction({ account: accountModel }));
        dispatch(setLogin({ isLogin: true }));
      } catch (e) {
        console.log(e);
      }
    })();
  }, [dispatch]);
};

export default useAccountLogin;
