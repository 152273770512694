import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import { GlobalStyle } from "config/global-style";
import themeConfig from "config/themes";
import useAccountLogin from "hooks/use-account-login";
import AdminRoutes from "routes";
import "./App.css";
function App() {
  useAccountLogin();
  return (
    <>
      <MantineProvider theme={themeConfig} withCssVariables withStaticClasses>
        <ModalsProvider>
          <GlobalStyle />
          <Notifications position={"top-right"} />
          <AdminRoutes />
        </ModalsProvider>
      </MantineProvider>
    </>
  );
}

export default App;
