import { css } from "styled-components";

export const TypographyConfigStyled = css`
  .mantine-Title-root {
    &[data-order="1"] {
      font-size: 40px;
      font-style: normal;
      line-height: 48px; /* 120% */
      color: #212529;
    }
    &[data-order="2"] {
      color: #212529;
      font-size: 32px;
      font-style: normal;
      line-height: 40px; /* 125% */
    }
    &[data-order="3"] {
      color: #212529;

      font-size: 24px;
      font-style: normal;
      line-height: 32px; /* 133.333% */
    }
    &[data-order="4"] {
      color: #212529;
      font-size: 20px;
      font-style: normal;
      line-height: 24px; /* 120% */
    }
    &[data-order="5"] {
      color: #212529;
      font-size: 16px;
      font-style: normal;
      line-height: 20px; /* 125% */
    }
    &[data-order="6"] {
      color: #212529;
      font-size: 12px;
      font-style: normal;
      line-height: 16px; /* 133.333% */
    }
  }

  .mantine-Text-root {
    &[data-size="xxs"] {
      font-size: 10px;
      font-style: normal;
      line-height: 12px; /* 120% */
    }
    &[data-size="xs"] {
      font-size: 12px;
      font-style: normal;
      line-height: 14px; /* 116.667% */
    }
    &[data-size="sm"] {
      font-size: 14px;
      font-style: normal;
      line-height: 16px; /* 114.286% */
    }
    &[data-size="md"] {
      font-size: 16px;
      font-style: normal;
      line-height: 18px; /* 112.5% */
    }
    &[data-size="lg"] {
      font-size: 18px;
      font-style: normal;
      line-height: 20px; /* 111.111% */
    }
    &[data-size="xl"] {
      font-size: 20px;
      font-style: normal;
      line-height: 24px; /* 120% */
    }
  }
`;
