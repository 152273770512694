import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/user.slice";
import appSettingSlice from "./slices/app-setting.slice";
// import các reducers ở đây

export const store = configureStore({
  reducer: {
    // Thêm reducers ở đây
    // ví dụ: posts: postsReducer,
    user: userSlice,
    appSetting: appSettingSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
