import { Circle } from "@phosphor-icons/react";
import TranslationEnum from "config/enums/translation";
import i18n, { initPromise } from "i18n";
import { MenuModel } from "models/menu/menu.model";
import React from "react";
import OrderMenuIcon from "shared/icons/order-menu";

const OrderListPage = React.lazy(() => import("modules/orders/list-page"));
const OrderDetailPage = React.lazy(() => import("modules/orders/detail-page"));
const OrderCreationPage = React.lazy(
  () => import("modules/orders/creation-page")
);
const OrderUpdatePage = React.lazy(() => import("modules/orders/update-page"));

const orderMenuConfig: MenuModel[] = await (async () => {
  await initPromise;
  return [
    {
      key: "orders",
      title: i18n.t(TranslationEnum.ORDER),
      path: "/orders",
      icon: OrderMenuIcon,
      active: true,
      permissions: [],
      subMenu: [
        {
          key: "orders-list",
          title: i18n.t(TranslationEnum.ORDER_LIST),
          icon: Circle,
          component: OrderListPage,
          subMenu: [],
          isMenu: true,
          active: true,
          permissions: [],
        },
        {
          key: "orders-detail",
          title: i18n.t(TranslationEnum.ORDER_DETAIL),
          icon: Circle,
          path: "/:id",
          component: OrderDetailPage,
          subMenu: [],
          isMenu: false,
          active: true,
          permissions: [],
        },
        {
          key: "orders-create",
          title: i18n.t(TranslationEnum.ADD_NEW_ORDER),
          path: "/create",
          component: OrderCreationPage,
          icon: Circle,
          subMenu: [],
          isMenu: true,
          active: true,
          permissions: [],
        },
        {
          key: "orders-update",
          title: i18n.t(TranslationEnum.UPDATE_ORDER),
          icon: Circle,
          path: "/update/:id",
          component: OrderUpdatePage,
          subMenu: [],
          isMenu: false,
          active: true,
          permissions: [],
        },
      ],
      isMenu: true,
    },
  ];
})();

export default orderMenuConfig;
