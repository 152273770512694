import { css } from "styled-components";

export const ButtonIconConfigStyled = css`
  .mantine-ActionIcon-root {
    &[data-size="xs"] {
      height: 16px;
      width: 16px;
      font-size: 12px;
    }

    &[data-size="sm"] {
      height: 24px;
      width: 24px;
      font-size: 20px;
    }

    &[data-size="md"] {
      height: 32px;
      width: 32px;
      font-size: 24px;
    }

    &[data-size="lg"] {
      height: 40px;
      width: 40px;
      font-size: 32px;
    }

    &[data-size="xl"] {
      height: 56px;
      width: 56px;
      font-size: 38px;
    }
    &[data-disabled="true"] {
      background: var(--mantine-color-gray-2);
    }
  }
`;
