import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AppSettingState {
  collapseOpened: boolean;
  selectedKeyMenu: string;
}

const initialState: AppSettingState = {
  collapseOpened: true,
  selectedKeyMenu: "",
};

const appSettingSlice = createSlice({
  name: "app-setting",
  initialState,
  reducers: {
    changeMenuKeyAction(state, action: PayloadAction<any>) {
      state.selectedKeyMenu = action.payload.selectedKeyMenu;
    },
    toggleCollapseAction(state) {
      state.collapseOpened = !state.collapseOpened;
    },
  },
});

export const { toggleCollapseAction, changeMenuKeyAction } =
  appSettingSlice.actions;
export default appSettingSlice.reducer;
