import { createGlobalStyle } from "styled-components";
import { ResetConfigStyled } from "./reset";
import { ButtonConfigStyled } from "./button.config";
import { TypographyConfigStyled } from "./typography.config";
import { ButtonIconConfigStyled } from "./action-icon.config";
import { TextInputConfigStyled } from "./text-input.config";
import { FormConfigStyled } from "./form.config";
import { MantineConfigStyled } from "./custom-mantine.config";

export const GlobalStyle = createGlobalStyle` 
    ${ResetConfigStyled} 
    ${FormConfigStyled}
    ${MantineConfigStyled}
    ${ButtonConfigStyled}
    /* ${ButtonIconConfigStyled}
    
    ${TypographyConfigStyled}
    ${TextInputConfigStyled} */
    
`;
