import { AppShell } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import LoadingPage from "../loading-page";

const FallBackLayout: React.FC = () => {
  const collapseOpened = useSelector(
    (state: RootState) => state.appSetting.collapseOpened
  );
  const isLogin = useSelector((state: RootState) => state.user?.isLogin);

  if (!isLogin) {
    return <LoadingPage message="đang tải ... " />;
  }
  return (
    <AppShell
      layout="alt"
      header={{ height: 56 }}
      navbar={{
        width: collapseOpened ? 218 : 112,
        breakpoint: "sm",
        collapsed: { mobile: false, desktop: false },
      }}
    >
      <AppShell.Header py={8} px={20}></AppShell.Header>
      <AppShell.Navbar bg="gray.9"></AppShell.Navbar>
      <AppShell.Main bg="gray.0"></AppShell.Main>
    </AppShell>
  );
};

export default FallBackLayout;
