import { UrlConfig } from "config/url.config";

const checkUserPermission = (
  partialAcceptPermissions: Array<string> = [],
  currentPermissions: Array<string> = []
): boolean => {
  if (partialAcceptPermissions.length === 0) {
    return true;
  }

  // kiểm tra quyền truy cập của use nếu chưa có hoặc không chưa quyền nào => không cho truy cập
  if (currentPermissions.length === 0) {
    return false;
  }

  // nếu trong profile có 1 quyền nào đó so với quyền cần có => được phép truy cập
  const hasPermission = partialAcceptPermissions.some((element) => {
    return currentPermissions.includes(element);
  });

  return hasPermission;
};
export const SSO_POPUP_BROADCAST_CHANNEL_KEY = "SSO_broadcast_channel";
let windowObjectReference: Window | null = null;

function openLoginPopupSSO() {
  if (windowObjectReference && !windowObjectReference.closed) {
    windowObjectReference.focus();
    return;
  }

  window.addEventListener("beforeunload", () => {
    closeLoginPopupSSO();
  });

  // Tính toán vị trí của cửa sổ để nó nằm chính giữa màn hình
  const width = 400;
  const height = 700;
  const left = window.screen.width / 2 - width / 2;
  const top = window.screen.height / 2 - height / 2;

  // Mở cửa sổ mới với tên cụ thể "window-login"
  windowObjectReference = window.open(
    UrlConfig.LOGIN,
    "window-login",
    `top=${top},left=${left},width=${width},height=${height}`
  );
}
const closeLoginPopupSSO = () => {
  windowObjectReference && windowObjectReference.close();
};

const authUtils = {
  checkUserPermission,
  openLoginPopupSSO,
  closeLoginPopupSSO,
};

export default authUtils;
