const AppConfig = {
  timeOut: 20000,
  ENV: process.env.REACT_APP_ENVIRONMENT, // DEV,UAT,PROD
  apiVersion: process.env.REACT_APP_API_VERSION,
  currency: "VND",
  BaseApiUrl: `/admin/${process.env.REACT_APP_API_VERSION}`,
  CDN: process.env.REACT_APP_CDN,
};

export default AppConfig;
