import { MenuModel } from "models/menu/menu.model";
import orderMenuConfig from "./order-menu.config";
import React from "react";
import { ChartBar } from "@phosphor-icons/react";
import i18n, { initPromise } from "i18n";
import TranslationEnum from "config/enums/translation";
const DashboardPage = React.lazy(() => import("modules/dashboard"));
const rootMenuConfig: MenuModel[] = await (async () => {
  await initPromise;
  return [
    {
      key: "dash-board",
      path: "/",
      title: i18n.t(TranslationEnum.OVERVIEW),
      icon: ChartBar,
      component: DashboardPage,
      subMenu: [],
      isMenu: true,
      active: true,
    },
    ...orderMenuConfig,
  ];
})();

export default rootMenuConfig;
