enum TranslationEnum {
  WELCOME = "welcome",
  OVERVIEW = "overview",
  ORDER = "order",
  ORDER_LIST = "order_list",
  ADD_NEW_ORDER = "add_new_order",
  UPDATE_ORDER = "update_order",
  ORDER_DETAIL = "order_detail",
}

export default TranslationEnum;
