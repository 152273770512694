import { css } from "styled-components";

export const ButtonConfigStyled = css`
  .mantine-Button-root {
    &[data-disabled="true"] {
      background: var(--mantine-color-gray-2);
    }
    &[data-variant="default"] {
      border: 1px solid var(--mantine-color-gray-2);
    }
  }
`;
