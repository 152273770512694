import { createTheme } from "@mantine/core";

export const colorsConfig = createTheme({
  colors: {
    // Add your color
    gray: [
      "#F9FAFB", //0
      "#F3F4F6", //1
      "#E5E7EB", //2
      "#D1D5DB", //3
      "#9CA3AF", //4
      "#6B7280", //5
      "#4B5563", //6
      "#374151", //7
      "#1F2A37", //8
      "#111928", //9
    ],
    red: [
      "#fff5f5", //0
      "#ffe3e3", //1
      "#ffc9c9", //2
      "#ffa8a8", //3
      "#ff8787", //4
      "#ff6b6b", //5
      "#fa5252", //6
      "#f03e3e", //7
      "#e03131", //8
      "#c92a2a", //9
    ],
    pink: [
      "#fff0f6", //0
      "#ffdeeb", //1
      "#fcc2d7", //2
      "#faa2c1 ", //3
      "#f783ac", //4
      "#f06595", //5
      "#e64980", //6
      "#d6336c", //7
      "#c2255c", //8
      "#a61e4d", //9
    ],
    grape: [
      "#f8f0fc", //0
      "#f3d9fa", //1
      "#eebefa", //2
      "#e599f7", //3
      "#da77f2", //4
      "#cc5de8", //5
      "#be4bdb", //6
      "#ae3ec9", //7
      "#9c36b5 ", //8
      "#862e9c", //9
    ],
    violet: [
      "#f3f0ff", //0
      "#e5dbff", //1
      "#d0bfff", //2
      "#b197fc", //3
      "#9775fa", //4
      "#845ef7", //5
      "#7950f2", //6
      "#7048e8", //7
      "#6741d9", //8
      "#5f3dc4", //9
    ],
    indigo: [
      "#edf2ff", //0
      "#dbe4ff", //1
      "#bac8ff", //2
      "#91a7ff", //3
      "#748ffc", //4
      "#5c7cfa", //5
      "#4c6ef5", //6
      "#4263eb", //7
      "#3b5bdb", //8
      "#364fc7", //9
    ],
    blue: [
      "#e7f5ff", //0
      "#d0ebff", //1
      "#a5d8ff", //2
      "#74c0fc", //3
      "#4dabf7", //4
      "#339af0", //5
      "#228be6", //6
      "#1c7ed6", //7
      "#1971c2", //8
      "#1864ab", //9
    ],
    cyan: [
      "#e3fafc", //0
      "#c5f6fa", //1
      "#99e9f2", //2
      "#66d9e8", //3
      "#3bc9db", //4
      "#22b8cf", //5
      "#15aabf", //6
      "#1098ad", //7
      "#0c8599", //8
      "#0b7285", //9
    ],
    teal: [
      "#e6fcf5", //0
      "#c3fae8", //1
      "#96f2d7", //2
      "#63e6be", //3
      "#38d9a9", //4
      "#20c997", //5
      "#12b886", //6
      "#0ca678", //7
      "#099268", //8
      "#087f5b", //9
    ],
    green: [
      "#ebfbee", //0
      "#d3f9d8", //1
      "#b2f2bb", //2
      "#8ce99a", //3
      "#69db7c", //4
      "#51cf66", //5
      "#40c057", //6
      "#37b24d", //7
      "#2f9e44", //8
      "#2b8a3e", //9
    ],
    lime: [
      "#f4fce3", //0
      "#e9fac8", //1
      "#d8f5a2", //2
      "#c0eb75", //3
      "#a9e34b", //4
      "#94d82d", //5
      "#82c91e", //6
      "#74b816", //7
      "#66a80f", //8
      "#5c940d", //9
    ],
    yellow: [
      "#fff9db", //0
      "#fff3bf", //1
      "#ffec99", //2
      "#ffe066", //3
      "#ffd43b", //4
      "#fcc419", //5
      "#fab005", //6
      "#f59f00", //7
      "#f08c00", //8
      "#e67700", //9
    ],
    orange: [
      "#fff4e6", //0
      "#ffe8cc", //1
      "#ffd8a8", //2
      "#ffc078", //3
      "#ffa94d", //4
      "#ff922b", //5
      "#fd7e14", //6
      "#f76707", //7
      "#e8590c", //8
      "#d9480f", //9
    ],
    primary: [
      "#efeffa",
      "#dcdbef",
      "#b4b4e0",
      "#8b8ad2",
      "#6967c6",
      "#5350bf",
      "#4945bd",
      "#3a37a7",
      "#2A2A86",
      "#1E1E73",
    ],
    secondary: [
      "#fff9e1", //0
      "#fff0cd", //1
      "#fde09d", //2
      "#fccf67", //3
      "#fbc13b", //4
      "#fab720", //5
      "#FAB005", //6
      "#df9d00", //7
      "#c78b00", //8
      "#DE9A1C", //9
    ],
  },
});
