import AccountDTO from "models/account/account.dto";

// src/app/slices/uiSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
  account: AccountDTO | null;
  isLogin: boolean;
}

const initialState: UserState = { account: null, isLogin: false };

export const UserSlice = createSlice({
  name: "user-login",
  initialState,
  reducers: {
    setAccountLoginAction: (state, action: PayloadAction<any>) => {
      state.account = action.payload.account;
    },
    setLogin: (state, action: PayloadAction<any>) => {
      state.isLogin = action.payload.isLogin;
    },
  },
});

export const { setAccountLoginAction, setLogin } = UserSlice.actions;
export default UserSlice.reducer;
