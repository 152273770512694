import { colorsConfig } from "./colors.config";
import { createTheme, rem } from "@mantine/core";

const themeConfig = createTheme({
  fontFamily: "Inter, sans-serif",
  fontSizes: {
    xs: "12px",
    sm: "14px",
    md: "16px", // Default font size
    lg: "18px",
    xl: "20px",
  },
  ...colorsConfig,
  shadows: {
    md: "1px 1px 3px rgba(0, 0, 0, .25)",
    xl: "5px 5px 3px rgba(0, 0, 0, .25)",
  },
  headings: {
    // fontFamily: "Roboto, sans-serif",
    sizes: {
      h1: { fontSize: rem(36) },
    },
  },
  components: {
    Text: {
      defaultProps: {
        lh: "16px",
        fz: "14px",
        color: "gray.9",
      },
      vars: () => ({}),
    },
    Button: {
      defaultProps: {
        radius: "md",
        fw: 400,
      },
    },
    // ActionIcon: {
    //   defaultProps: {
    //     size: "sm",
    //     color: "primary.8",
    //     radius: "md",
    //   },
    // },
    Input: {
      defaultProps: {
        size: "sm",
        radius: "md",
      },
      vars: () => ({}),
    },
    TextInput: {
      defaultProps: {
        size: "sm",
        radius: "md",
      },
    },

    Textarea: {
      defaultProps: {
        size: "sm",
        radius: "md",
      },
      vars: () => ({}),
    },
    Checkbox: {
      defaultProps: {
        size: "sm",
        color: "primary.8",
      },
    },
    Chip: {
      defaultProps: {
        size: "sm",
        color: "primary.8",
      },
    },
    Switch: {
      defaultProps: {
        size: "sm",
        color: "primary.8",
      },
    },
    Radio: {
      defaultProps: {
        size: "sm",
        color: "primary.8",
      },
    },
    Select: {
      defaultProps: {
        size: "sm",
        radius: "md",
      },
    },
    Slider: {
      defaultProps: {
        size: "md",
        color: "primary.8",
      },
    },
  },
});
export default themeConfig;
