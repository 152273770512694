import { Loader, Center, Text, Stack } from "@mantine/core";

const LoadingPage = ({ message = "Loading, please wait..." }) => {
  return (
    <Center style={{ height: "100vh" }}>
      <Stack align="center">
        <Loader size="xl" />
        <Text>{message}</Text>
      </Stack>
    </Center>
  );
};

export default LoadingPage;
