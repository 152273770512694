import axios from "axios";
import AppConfig from "config/app.config";
import { UrlConfig } from "config/url.config";
import { throttle } from "lodash";
import qs from "query-string";
import authUtils, { SSO_POPUP_BROADCAST_CHANNEL_KEY } from "shared/utils/auth";
import ydNotifications from "shared/utils/notifications";
const channel = new BroadcastChannel(SSO_POPUP_BROADCAST_CHANNEL_KEY);

const handleUnauthenticated = (error: any) => {
  const url = error.config.url;
  if (typeof url === "string" && ["/admin/account.json"].includes(url)) {
    window.location.href = UrlConfig.LOGIN; // reload page to use proxy server
  } else {
    handleUnauthenticatedPopup();
  }
};

const handleUnauthenticatedPopup = () => {
  notifyUnauthenticatedPopupThrottle();
  channel.onmessage = (e) => {
    subscribeAuthenticationSuccessThrottle(e);
  };
};

const subscribeAuthenticationSuccessThrottle: ReturnType<typeof throttle> =
  throttle((event: MessageEvent<any>) => {
    if (event.data) {
      ydNotifications.success(event.data);
      authUtils.closeLoginPopupSSO();
    }
  }, 5000);

const notifyUnauthenticatedPopupThrottle = throttle(() => {
  ydNotifications.error("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại.");
  authUtils.openLoginPopupSSO();
}, 5000);

const handleUnauthorized = (error: any) => {
  if (error.response.status === 403) {
    ydNotifications.error(
      "Bạn không đủ quyền truy cập, vui lòng liên hệ với IT để được cấp quyền."
    );
  }
};

const createAxiosInstance = (
  serviceName: "unicorn" | "oos" | "admin",
  baseURL?: string
) => {
  const axiosInstance = axios.create({
    timeout: AppConfig.timeOut,
    baseURL: baseURL,
  });

  axiosInstance.defaults.withCredentials = true;
  axiosInstance.defaults.paramsSerializer = (params) => {
    return qs.stringify(params, { arrayFormat: "comma" });
  };

  // Thêm một interceptor để tự động đính kèm token vào mọi yêu cầu
  axiosInstance.interceptors.request.use(
    (config) => {
      // console.log("config request", config);
      config.headers["X-Client-Git-Version"] = `${
        process.env.REACT_APP_GIT_COMMIT_HASH
          ? process.env.REACT_APP_GIT_COMMIT_HASH
          : "no-git-commit-hash"
      }`;
      if (serviceName === "oos")
        config.data = { ...config.data, service_type: "UNICORN_SALE_ONLINE" };
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  if (serviceName === "oos" || serviceName === "admin") {
    axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        switch (error.response?.status) {
          case 401:
            handleUnauthenticated(error);
            break;
          case 403:
            handleUnauthorized(error);
            break;
          default:
            return Promise.reject(error);
        }
      }
    );
  } else if (serviceName === "unicorn") {
    axiosInstance.interceptors.response.use(
      (response) => {
        if (response.data.code !== 20000000) {
          return Promise.reject({
            message: response.data.message ?? response.data.errors.join(", "),
          });
        } else {
          if (response.data.data.items) {
            return { ...response, data: response.data.data.items };
          }
          return { ...response, data: response.data.data };
        }
      },
      async (error) => {
        switch (error.response?.status) {
          case 401:
            handleUnauthenticated(error);
            break;
          case 403:
            handleUnauthorized(error);
            break;
          case 500:
            return Promise.reject({ message: "Lỗi máy chủ" });
          default:
            return Promise.reject(error);
        }
      }
    );
  }

  return axiosInstance;
};

const axiosInstanceOOS = createAxiosInstance("oos");
export default axiosInstanceOOS;
export const axiosInstanceAdmin = createAxiosInstance("admin");
export const axiosInstanceUnicorn = createAxiosInstance("unicorn");
