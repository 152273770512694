import { css } from "styled-components";

export const FormConfigStyled = css`
  .mantine-DatePickerInput-wrapper[data-size="sm"] {
    height: 34px;
    min-height: 34px;
  }
  .mantine-Input-input {
    border: 1px solid var(--mantine-color-gray-2);
    ::placeholder {
      color: var(--mantine-color-gray-4); /* Đặt màu placeholder theo ý muốn */
    }
  }
  .mantine-Input-wrapper {
    &[data-size="sm"] {
      input,
      textarea {
        height: 34px;
        min-height: 34px;
      }
    }
    &[data-variant="filled"] {
      --input-bg: var(--mantine-color-gray-2) !important;
    }
    &[data-disabled="true"] {
      --input-disabled-bg: var(--mantine-color-gray-2) !important;
      --input-disabled-color: var(--mantine-color-gray-4) !important;
    }
  }

  .mantine-Select-dropdown {
    .mantine-Select-option {
      padding: 5px 8px;
    }
  }

  .mantine-Radio-inner {
    input[disabled] {
      background-color: var(--mantine-color-gray-2);
      border-color: var(--mantine-color-gray-4);
    }

    .mantine-Radio-label[data-disabled="true"] {
      color: var(--mantine-color-gray-4);
    }
  }
`;
