import rootMenuConfig from "config/menu";
import { UrlConfig } from "config/url.config";
import _ from "lodash";
import { MenuModel } from "models/menu/menu.model";
import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import FallBackLayout from "shared/layout/fallback";

const LoginSSO = React.lazy(() => import("modules/login"));
const PageNotFound = React.lazy(() => import("modules/error/not-found"));

interface RouteModel {
  path: string;
  component: any;
  key: string;
}

const getRoutes = (menus: MenuModel[], path?: string) => {
  const routes = menus.map((route) => {
    const result: RouteModel[] = [];
    const _path = path ? path + (route.path ?? "") : route.path;
    if (route.component && route.active && _path) {
      _path &&
        result.push({
          path: _path,
          component: route.component,
          key: route.key,
        });
    }
    if (route.subMenu.length !== 0) {
      const subResult = _.flatten(getRoutes(route.subMenu, route.path));
      result.push(...subResult);
    }
    return result;
  });
  return _.flatten(routes);
};

const AdminRoutes: React.FC = () => {
  const [routes] = useState(_.flatten(getRoutes(rootMenuConfig)));
  return (
    <>
      <BrowserRouter>
        <React.Suspense fallback={<FallBackLayout />}>
          <Routes>
            {routes.map((route, index) => (
              <Route
                path={route.path}
                element={<route.component />}
                key={route.key + index}
              />
            ))}
            <Route path={UrlConfig.LOGIN} element={<LoginSSO />} key="login" />
            <Route path="*" element={<PageNotFound />} key={"404"} />
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    </>
  );
};

export default AdminRoutes;
